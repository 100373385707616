import { connect } from "react-redux"

import AlertDialogView from "./alert-dialog-view"
import { hideAlert } from "../../redux/alert/actions"
import { State } from "../../redux/types"
import { ViewProps } from "./alert-dialog-types"

const mapStateToProps = (state: State): ViewProps => {
  return state.alert
}

export default connect(mapStateToProps, { hideAlert })(AlertDialogView)
