import { AlertActionType, AlertHideAction, AlertShowAction } from "./types"

export const showAlert = (title: string, message: string): AlertShowAction => ({
  type: AlertActionType.ALERT_SHOW,
  payload: {
    title,
    message,
  },
})

export const hideAlert = (): AlertHideAction => ({
  type: AlertActionType.ALERT_HIDE,
})
