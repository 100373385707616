import { connect } from "react-redux"

import { State } from "../../../redux/types"
import { ViewProps } from "./top-bar-types"
import TopBarView from "./top-bar-view"
import { countryData } from "../../../data/order"

const mapStateToProps = (state: State): ViewProps => {
  return {
    shippingCostsThreshold:
      countryData[state.order.details.shippingCountry].shippingCostsThreshold,
  }
}

export default connect(mapStateToProps)(TopBarView)
