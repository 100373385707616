import { LoadState, SaveState, State } from "./types"
import { countryData, paymentMethodData } from "../data/order"
import { productData } from "../data/products"
import { Country, PaymentMethod } from "../api/sync"

const localStorageVersion = `-1582970688`

export const loadState = (): LoadState | undefined => {
  try {
    const serialized = localStorage.getItem(`state${localStorageVersion}`)

    if (serialized === null) {
      return undefined
    }

    const unserialized = JSON.parse(serialized) as SaveState

    if (
      !unserialized.expiresAt ||
      new Date() > new Date(unserialized.expiresAt)
    ) {
      return undefined
    }

    const state = { cart: unserialized.cart, order: unserialized.order }

    // Validate cart products, filter out non-existing
    state.cart = state.cart.filter(
      cartRecord => productData[cartRecord.product]
    )

    // Validate order details enums
    if (!paymentMethodData[state.order.details.paymentMethod]) {
      state.order.details.paymentMethod = PaymentMethod.IDEAL
    }

    if (!countryData[state.order.details.invoiceCountry]) {
      state.order.details.invoiceCountry = Country.NETHERLANDS
    }

    if (!countryData[state.order.details.shippingCountry]) {
      state.order.details.shippingCountry = Country.NETHERLANDS
    }

    return state
  } catch (err) {
    return undefined
  }
}

export const saveState = (state: State) => {
  try {
    const expiresAt = new Date()
    expiresAt.setDate(expiresAt.getDate() + 14)

    const save: SaveState = {
      expiresAt: expiresAt,
      cart: state.cart,
      order: state.order,
    }

    const serialized = JSON.stringify(save)
    localStorage.setItem(`state${localStorageVersion}`, serialized)
  } catch {
    // Ignore write errors
  }
}
