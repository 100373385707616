import { Product, productData as apiProductData } from "../api/sync"

// Create object with product data, merged with the data from api.ts
export const productData: {
  [key in Product]: {
    title: string
    description: string
    price: number
    images: string[]
  }
} = {
  [Product.TODOXS]: {
    title: `Proefpakket To Do Cards`,
    description: `Wil je onze To Do Cards een keer uitproberen? Dat kan met dit proefpakket van 10 stuks.`,
    price: apiProductData[Product.TODOXS].price,
    images: [`proefpakket-to-do.jpg`],
  },

  [Product.TODO]: {
    title: `Pakket 200 x To Do Cards`,
    description: `Handige brievenbusdoos met 200 To Do Cards. Perfect voor het bijhouden van jouw dagelijkse taken.`,
    price: apiProductData[Product.TODO].price,
    images: [
      `pakket-200-x-to-do-1.jpg`,
      `pakket-200-x-to-do-2.jpg`,
      `pakket-200-x-to-do-3.jpg`,
      `to-do-brievenbusdoos.jpg`,
    ],
  },

  [Product.TODOXL]: {
    title: `Pakket 750 x To Do Cards`,
    description: `Grote stevige doos met 750 To Do Cards. Ideaal om te delen met jouw collega’s op kantoor.`,
    price: apiProductData[Product.TODOXL].price,
    images: [
      `pakket-750-x-to-do-1.jpg`,
      `pakket-750-x-to-do-2.jpg`,
      `pakket-750-x-to-do-3.jpg`,
      `to-do-klepdoos.jpg`,
    ],
  },
}
