import React, { useState } from "react"

import {
  AppBar,
  Badge,
  Container,
  Divider,
  Drawer,
  IconButton,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  useScrollTrigger,
} from "@material-ui/core"

import {
  Home,
  KeyboardReturnRounded,
  LocalShipping,
  Mail,
  Menu,
  ShoppingCart,
} from "@material-ui/icons"

import { Link as GatsbyLink } from "gatsby"

import logo from "../../../assets/images/logo.png"
import { ViewProps } from "./nav-bar-types"
import TopBar from "../top-bar"

const useStyles = makeStyles(theme => ({
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
  },

  toolbar: {
    justifyContent: `space-between`,
    padding: `${theme.spacing(0.5)}px 0`,
    display: `flex`,
    alignItems: `center`,

    [theme.breakpoints.up(`md`)]: {
      padding: `${theme.spacing(1)}px 0`,
    },
  },

  menuButton: {
    display: `flex`, // Needed to remove ghost margin

    "&:hover": {
      cursor: `pointer`,
    },

    [theme.breakpoints.up(`md`)]: {
      display: `none`,
    },
  },

  logo: {
    height: 25,
    display: `flex`, // Needed to remove ghost margin

    [theme.breakpoints.up(`lg`)]: {
      height: 30,
    },
  },

  menuItem: {
    fontSize: 14,
    fontWeight: 500,
    display: `none`,

    [theme.breakpoints.up(`md`)]: {
      display: `block`,
    },

    [theme.breakpoints.up(`lg`)]: {
      fontSize: 16,
    },
  },
}))

const NavBarView = ({ cartCount }: ViewProps) => {
  const classes = useStyles()
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  })

  const [showDrawer, setShowDrawer] = useState(false)

  const toggleDrawer = () => {
    setShowDrawer(show => !show)
  }

  return (
    <AppBar
      className={classes.root}
      color="inherit"
      position="sticky"
      elevation={trigger ? 4 : 0}
    >
      <TopBar />

      <Container maxWidth="lg">
        <div className={classes.toolbar}>
          <Link onClick={toggleDrawer} className={classes.menuButton}>
            <Menu />
          </Link>

          <GatsbyLink to="/">
            <img src={logo} className={classes.logo} alt="Logo" />
          </GatsbyLink>

          <IconButton
            color="inherit"
            component={GatsbyLink}
            to="/bestellen/winkelwagen"
          >
            <Badge badgeContent={cartCount.toString()} color="secondary">
              <ShoppingCart />
            </Badge>
          </IconButton>
        </div>
      </Container>

      <Drawer anchor="left" open={showDrawer} onClose={toggleDrawer}>
        <List>
          <ListItem
            button
            component={GatsbyLink}
            to="/bestellen/winkelwagen"
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <Badge badgeContent={cartCount.toString()} color="secondary">
                <ShoppingCart />
              </Badge>
            </ListItemIcon>
            <ListItemText>Winkelwagen</ListItemText>
          </ListItem>

          <Divider />

          <ListItem button component={GatsbyLink} to="/" onClick={toggleDrawer}>
            <ListItemIcon>
              <Home />
            </ListItemIcon>
            <ListItemText>Home</ListItemText>
          </ListItem>

          <ListItem
            button
            component={GatsbyLink}
            to="/retourbeleid"
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <KeyboardReturnRounded />
            </ListItemIcon>
            <ListItemText>Retourbeleid</ListItemText>
          </ListItem>

          <ListItem
            button
            component={GatsbyLink}
            to="/verzenden-en-betalen"
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <LocalShipping />
            </ListItemIcon>
            <ListItemText>Verzenden en betalen</ListItemText>
          </ListItem>

          <ListItem
            button
            component={GatsbyLink}
            to="/contact"
            onClick={toggleDrawer}
          >
            <ListItemIcon>
              <Mail />
            </ListItemIcon>
            <ListItemText>Contact</ListItemText>
          </ListItem>
        </List>
      </Drawer>
    </AppBar>
  )
}

export default NavBarView
