import React from "react"

import PageShell from "../../src/components/page-shell"
import RootShell from "../../src/components/root-shell"

export const wrapRootElement = ({ element }) => {
  return <RootShell>{element}</RootShell>
}

export const wrapPageElement = ({ element, props }) => {
  if (props.pageContext.type === `payment`) {
    return element
  }

  return <PageShell>{element}</PageShell>
}
