/*
These values should be in sync with the backend
 */

export const shop = `todocards_nl`

export enum Country {
  NETHERLANDS = `nl`,
  BELGIUM = `be`,
}

export const countryData: {
  [key in Country]: {
    shippingCostsThreshold: number
    shippingCosts: number
  }
} = {
  [Country.NETHERLANDS]: {
    shippingCostsThreshold: 2995,
    shippingCosts: 395,
  },

  [Country.BELGIUM]: {
    shippingCostsThreshold: 2995,
    shippingCosts: 495,
  },
}

export enum PaymentMethod {
  IDEAL = `ideal`,
  PAYPAL = `paypal`,
  BANCONTACT = `bancontact`,
  KBC_CBC_PAYMENT_BUTTON = `kbc_cbc_payment_button`,
  ING_HOME_PAY = `ing_home_pay`,
}

export enum OrderStatus {
  PAID = `paid`,
  CANCELED = `canceled`,
  EXPIRED = `expired`,
  FAILED = `failed`,
}

export enum Product {
  TODOXS = `TODOXS`,
  TODO = `TODO`,
  TODOXL = `TODOXL`,
}

export const productData: {
  [key in Product]: {
    price: number
  }
} = {
  [Product.TODOXS]: {
    price: 95,
  },

  [Product.TODO]: {
    price: 1195,
  },

  [Product.TODOXL]: {
    price: 3495,
  },
}
