import { Product } from "../../api/sync"

export enum CartActionType {
  CART_ADD_PRODUCT = `CART_ADD_PRODUCT`,
  CART_REMOVE_PRODUCT = `CART_REMOVE_PRODUCT`,
  CART_INCREASE_QUANTITY = `CART_INCREASE_QUANTITY`,
  CART_DECREASE_QUANTITY = `CART_DECREASE_QUANTITY`,
  CART_RESET = `CART_RESET`,
}

export type CartUpdateAction = {
  type:
    | CartActionType.CART_ADD_PRODUCT
    | CartActionType.CART_REMOVE_PRODUCT
    | CartActionType.CART_INCREASE_QUANTITY
    | CartActionType.CART_DECREASE_QUANTITY
  payload: {
    product: Product
  }
}

export type CartResetAction = {
  type: CartActionType.CART_RESET
}

export type CartAction = CartUpdateAction | CartResetAction

export type CartState = {
  product: Product
  quantity: number
}[]
