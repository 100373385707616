import React from "react"

import { CssBaseline, responsiveFontSizes } from "@material-ui/core"
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles"

import { Provider } from "react-redux"
import { Helmet } from "react-helmet"

import AlertDialog from "../alert-dialog"
import { ViewProps } from "./root-shell-types"
import store from "../../redux/store"
import GlobalCss from "./global-css"

const defaultTheme = createMuiTheme()

let theme = createMuiTheme({
  typography: {
    h1: {
      fontWeight: 500,
      fontSize: `2rem`,
      lineHeight: 1.2,
      letterSpacing: 0,
    },

    h2: {
      fontWeight: 500,
      fontSize: `1.5rem`,
      lineHeight: 1.2,
      letterSpacing: 0,
    },

    h3: {
      fontWeight: 500,
      fontSize: `1.25rem`,
      lineHeight: 1.2,
      letterSpacing: 0,
    },

    h4: {
      fontWeight: 500,
      fontSize: `1rem`,
      lineHeight: 1.2,
      letterSpacing: 0,
    },

    h5: {
      fontWeight: 400,
      fontSize: `1rem`,
      fontStyle: `italic`,
      lineHeight: 1.2,
      letterSpacing: 0,
    },
  },

  palette: {
    primary: {
      main: `#2A3132`,
    },

    secondary: {
      main: `#C44900`,
    },

    text: {
      primary: `#2A3132`,
    },
  },

  props: {
    MuiLink: {
      underline: `none`,
      color: `inherit`,
    },
  },

  overrides: {
    MuiLink: {
      root: {
        "&:hover": {
          color: `#C44900`,
        },
      },
    },

    MuiCssBaseline: {
      "@global": {
        body: {
          backgroundColor: `#ffffff`,
        },
      },
    },

    MuiListItemIcon: {
      root: {
        minWidth: 0,
        fontSize: `0.75rem`,
        paddingRight: defaultTheme.spacing(2),
      },
    },

    MuiTypography: {
      gutterBottom: {
        marginBottom: defaultTheme.spacing(0.5),
      },
    },
  },
})

theme = responsiveFontSizes(theme)

const RootShellView = ({ children }: ViewProps) => {
  return (
    <Provider store={store}>
      <ThemeProvider theme={theme}>
        <Helmet htmlAttributes={{ lang: `nl` }}>
          <meta
            name="viewport"
            content="width=device-width, initial-scale=1.0"
          />

          <link
            href="https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,400;0,500;0,700;1,400&display=swap"
            rel="stylesheet"
          />
        </Helmet>

        <CssBaseline />
        <GlobalCss />
        {children}
        <AlertDialog />
      </ThemeProvider>
    </Provider>
  )
}

export default RootShellView
