import { CartAction, CartActionType, CartState } from "./types"
import { Product } from "../../api/sync"

const initialState: CartState = []

function findByProduct(state: CartState, product: Product) {
  return state.find(cartRecord => cartRecord.product === product)
}

function updateQuantity(state: CartState, product: Product, increase = true) {
  return state.map(record => {
    if (record.product === product) {
      if (increase) {
        record.quantity++
      } else {
        record.quantity--
      }
    }

    return record
  })
}

function addProduct(state: CartState, product: Product) {
  return [
    ...state,
    {
      product: product,
      quantity: 1,
    },
  ]
}

function removeProduct(state: CartState, product: Product) {
  return state.filter(record => {
    return record.product !== product
  })
}

export default function(state = initialState, action: CartAction): CartState {
  switch (action.type) {
    case CartActionType.CART_ADD_PRODUCT: {
      const cartRecord = findByProduct(state, action.payload.product)
      if (cartRecord) {
        return updateQuantity(state, action.payload.product)
      }

      return addProduct(state, action.payload.product)
    }

    case CartActionType.CART_INCREASE_QUANTITY: {
      return updateQuantity(state, action.payload.product)
    }

    case CartActionType.CART_DECREASE_QUANTITY: {
      const cartRecord = findByProduct(state, action.payload.product)
      if (cartRecord && cartRecord.quantity === 1) {
        return state
      }

      return updateQuantity(state, action.payload.product, false)
    }

    case CartActionType.CART_REMOVE_PRODUCT: {
      return removeProduct(state, action.payload.product)
    }

    case CartActionType.CART_RESET: {
      return initialState
    }

    default:
      return state
  }
}
