import { Country, PaymentMethod } from "../../api/sync"

export enum OrderActionType {
  ORDER_RESET = `ORDER_RESET`,
  ORDER_SET_DETAILS = `ORDER_SET_DETAILS`,
  ORDER_SET_REF = `ORDER_SET_REF`,
  ORDER_SET_SHIPPING_COUNTRY = `ORDER_SET_SHIPPING_COUNTRY`,
}

export type OrderResetAction = {
  type: OrderActionType.ORDER_RESET
}

export type OrderSetDetailsAction = {
  type: OrderActionType.ORDER_SET_DETAILS
  payload: {
    details: OrderDetails
  }
}

export type OrderSetRefAction = {
  type: OrderActionType.ORDER_SET_REF
  payload: {
    ref: string
  }
}

export type OrderSetShippingCountryAction = {
  type: OrderActionType.ORDER_SET_SHIPPING_COUNTRY
  payload: {
    country: Country
  }
}

export type OrderAction =
  | OrderSetDetailsAction
  | OrderSetRefAction
  | OrderResetAction
  | OrderSetShippingCountryAction

export type OrderDetails = {
  email: string
  paymentMethod: PaymentMethod
  shippingFirstName: string
  shippingLastName: string
  shippingCompanyName: string
  shippingStreet: string
  shippingStreetNumber: string
  shippingPostalCode: string
  shippingCity: string
  shippingCountry: Country
  invoiceSameAsShipping: boolean
  invoiceFirstName: string
  invoiceLastName: string
  invoiceCompanyName: string
  invoiceStreet: string
  invoiceStreetNumber: string
  invoicePostalCode: string
  invoiceCity: string
  invoiceCountry: Country
}

export type OrderState = {
  ref: string
  details: OrderDetails
}
