import React from "react"

import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@material-ui/core"

import { ViewDispatchActions, ViewProps } from "./alert-dialog-types"

const AlertDialogView = ({
  show,
  title,
  message,
  hideAlert,
}: ViewProps & ViewDispatchActions) => {
  const handleClose = () => {
    hideAlert()
  }

  return (
    <Dialog open={show} onClose={handleClose}>
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {message}
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose} color="primary">
          Sluiten
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AlertDialogView
