import { AlertAction, AlertActionType, AlertState } from "./types"

const initialState: AlertState = {
  show: false,
  title: ``,
  message: ``,
}

export default function(state = initialState, action: AlertAction): AlertState {
  switch (action.type) {
    case AlertActionType.ALERT_SHOW: {
      return {
        show: true,
        title: action.payload.title,
        message: action.payload.message,
      }
    }

    case AlertActionType.ALERT_HIDE: {
      return {
        ...state,
        show: false,
      }
    }

    default:
      return state
  }
}
