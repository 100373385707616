import React from "react"

import { Container, Link, makeStyles, Typography } from "@material-ui/core"
import { Link as GatsbyLink } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    padding: `${theme.spacing(1)}px 0`,
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.primary.contrastText,
  },

  inner: {
    [theme.breakpoints.up(`md`)]: {
      display: `flex`,
      justifyContent: `space-between`,
      alignItems: `center`,
    },
  },

  menu: {
    display: `flex`,
    alignItems: `center`,
    marginBottom: theme.spacing(1),
    fontWeight: 500,

    [theme.breakpoints.up(`md`)]: {
      marginBottom: 0,
    },
  },

  divider: {
    fontSize: `50%`,
    margin: `0 ${theme.spacing(2)}px`,
  },

  link: {
    color: theme.palette.primary.contrastText,
    textDecoration: `none`,

    "&:hover": {
      color: theme.palette.grey[300],
    },
  },

  attribution: {
    display: `none`,

    [theme.breakpoints.up(`md`)]: {
      display: `inline`,
    },
  },
}))

const SubFooterView = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.inner}>
          <Typography variant="body2" component="span" className={classes.menu}>
            <Link
              className={classes.link}
              component={GatsbyLink}
              to="/algemene-voorwaarden"
            >
              Algemene voorwaarden
            </Link>

            <span className={classes.divider}>|</span>

            <Link
              className={classes.link}
              component={GatsbyLink}
              to="/privacyverklaring"
            >
              Privacyverklaring
            </Link>

            <span className={classes.attribution}>
              <span className={classes.divider}>|</span>

              <a
                href="https://stories.freepik.com/"
                rel="nofollow"
                className={classes.link}
              >
                Illustration by Stories by Freepik
              </a>
            </span>
          </Typography>

          <Typography variant="body2" component="span" className="bold">
            Todocards.nl is onderdeel van TD Media
          </Typography>
        </div>
      </Container>
    </div>
  )
}

export default SubFooterView
