// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-algemene-voorwaarden-tsx": () => import("./../src/pages/algemene-voorwaarden.tsx" /* webpackChunkName: "component---src-pages-algemene-voorwaarden-tsx" */),
  "component---src-pages-bestellen-afgerond-tsx": () => import("./../src/pages/bestellen/afgerond.tsx" /* webpackChunkName: "component---src-pages-bestellen-afgerond-tsx" */),
  "component---src-pages-bestellen-betalen-tsx": () => import("./../src/pages/bestellen/betalen.tsx" /* webpackChunkName: "component---src-pages-bestellen-betalen-tsx" */),
  "component---src-pages-bestellen-gegevens-tsx": () => import("./../src/pages/bestellen/gegevens.tsx" /* webpackChunkName: "component---src-pages-bestellen-gegevens-tsx" */),
  "component---src-pages-bestellen-winkelwagen-tsx": () => import("./../src/pages/bestellen/winkelwagen.tsx" /* webpackChunkName: "component---src-pages-bestellen-winkelwagen-tsx" */),
  "component---src-pages-contact-tsx": () => import("./../src/pages/contact.tsx" /* webpackChunkName: "component---src-pages-contact-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-privacyverklaring-tsx": () => import("./../src/pages/privacyverklaring.tsx" /* webpackChunkName: "component---src-pages-privacyverklaring-tsx" */),
  "component---src-pages-retourbeleid-tsx": () => import("./../src/pages/retourbeleid.tsx" /* webpackChunkName: "component---src-pages-retourbeleid-tsx" */),
  "component---src-pages-verzenden-en-betalen-tsx": () => import("./../src/pages/verzenden-en-betalen.tsx" /* webpackChunkName: "component---src-pages-verzenden-en-betalen-tsx" */),
  "component---src-pages-zakelijk-tsx": () => import("./../src/pages/zakelijk.tsx" /* webpackChunkName: "component---src-pages-zakelijk-tsx" */)
}

