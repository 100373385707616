export enum AlertActionType {
  ALERT_SHOW = `ALERT_SHOW`,
  ALERT_HIDE = `ALERT_HIDE`,
}

export type AlertShowAction = {
  type: AlertActionType.ALERT_SHOW
  payload: {
    title: string
    message: string
  }
}

export type AlertHideAction = {
  type: AlertActionType.ALERT_HIDE
}

export type AlertAction = AlertShowAction | AlertHideAction

export type AlertState = {
  show: boolean
  title: string
  message: string
}
