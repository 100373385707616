import {
  Country,
  PaymentMethod,
  countryData as apiCountryData,
} from "../api/sync"

import bancontactImg from "../assets/images/payment-methods/bancontact.svg"
import idealImg from "../assets/images/payment-methods/ideal.svg"
import inghomepayImg from "../assets/images/payment-methods/inghomepay.svg"
import kbcImg from "../assets/images/payment-methods/kbc.svg"
import paypalImg from "../assets/images/payment-methods/paypal.svg"

export const countryData: {
  [key in Country]: {
    label: string
    shippingTime: string
    shippingCostsThreshold: number
    shippingCosts: number
  }
} = {
  [Country.NETHERLANDS]: {
    label: `Nederland`,
    shippingTime: `1 á 2 werkdagen`,
    shippingCostsThreshold:
      apiCountryData[Country.NETHERLANDS].shippingCostsThreshold,
    shippingCosts: apiCountryData[Country.NETHERLANDS].shippingCosts,
  },

  [Country.BELGIUM]: {
    label: `België`,
    shippingTime: `2 á 3 werkdagen`,
    shippingCostsThreshold:
      apiCountryData[Country.BELGIUM].shippingCostsThreshold,
    shippingCosts: apiCountryData[Country.BELGIUM].shippingCosts,
  },
}

export const paymentMethodData: {
  [key in PaymentMethod]: {
    label: string
    img: string
  }
} = {
  [PaymentMethod.IDEAL]: {
    label: `iDEAL`,
    img: idealImg,
  },

  [PaymentMethod.PAYPAL]: {
    label: `Paypal`,
    img: paypalImg,
  },

  [PaymentMethod.BANCONTACT]: {
    label: `Bancontact`,
    img: bancontactImg,
  },

  [PaymentMethod.KBC_CBC_PAYMENT_BUTTON]: {
    label: `KBC/CBC-betaalknop`,
    img: kbcImg,
  },

  [PaymentMethod.ING_HOME_PAY]: {
    label: `ING Home’Pay`,
    img: inghomepayImg,
  },
}
