import React from "react"

// Create some global utility classes
import { makeStyles } from "@material-ui/core/styles"

const useStyles = makeStyles(theme => ({
  "@global": {
    ".blockPaddingTop": {
      paddingTop: theme.spacing(4),

      [theme.breakpoints.up(`sm`)]: {
        paddingTop: theme.spacing(6),
      },
    },

    ".blockPaddingBottom": {
      paddingBottom: theme.spacing(4),

      [theme.breakpoints.up(`sm`)]: {
        paddingBottom: theme.spacing(6),
      },
    },

    ".mb05": {
      marginBottom: theme.spacing(0.5),
    },

    ".mb1": {
      marginBottom: theme.spacing(1),
    },

    ".mb2": {
      marginBottom: theme.spacing(2),
    },

    ".mr05": {
      marginRight: theme.spacing(0.5),
    },

    ".mr1": {
      marginRight: theme.spacing(1),
    },

    ".floatRight": {
      float: `right`,
    },

    ".bold": {
      fontWeight: 500,
    },
  },
}))

const GlobalCssView = () => {
  useStyles()
  return <></>
}

export default GlobalCssView
