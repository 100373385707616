import React from "react"

import { makeStyles } from "@material-ui/core/styles"

import Footer from "./footer"
import SubFooter from "./sub-footer"
import NavBar from "./nav-bar"
import { ViewProps } from "./page-shell-types"

const useStyles = makeStyles({
  root: {
    minHeight: `100vh`,
    display: `flex`,
    flexDirection: `column`,
  },
  main: {
    flexGrow: 1,
  },
})

const PageShellView = ({ children }: ViewProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <NavBar />

      <main className={classes.main}>{children}</main>

      <footer>
        <Footer />
        <SubFooter />
      </footer>
    </div>
  )
}

export default PageShellView
