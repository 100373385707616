import React from "react"

import { Container, Link, Typography } from "@material-ui/core"
import { makeStyles } from "@material-ui/core/styles"
import { KeyboardReturnRounded, LocalShipping, Mail } from "@material-ui/icons"
import { Link as GatsbyLink } from "gatsby"

import { ViewProps } from "./top-bar-types"
import CentsToEuro from "../../../utils/cents-to-euro"

const useStyles = makeStyles(theme => ({
  root: {
    borderTop: `5px solid ${theme.palette.secondary.main}`,
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
  },

  inner: {
    padding: `${theme.spacing(0.5)}px 0`,
    display: `flex`,
    justifyContent: `space-between`,

    [theme.breakpoints.down(`sm`)]: {
      display: `none`,
    },
  },

  kpi: {
    display: `flex`,
    alignItems: `center`,
    fontWeight: 500,
  },

  contactKpi: {
    [theme.breakpoints.down(`md`)]: {
      display: `none`,
    },
  },

  mr: {
    marginRight: theme.spacing(1),
  },
}))

const TopBarView = ({ shippingCostsThreshold }: ViewProps) => {
  const classes = useStyles()

  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <div className={classes.inner}>
          <Link component={GatsbyLink} to="/verzenden-en-betalen">
            <Typography
              variant="body2"
              component="span"
              className={classes.kpi}
            >
              <LocalShipping fontSize="inherit" className={classes.mr} />
              Gratis verzending vanaf {CentsToEuro(shippingCostsThreshold)}
            </Typography>
          </Link>

          <Link component={GatsbyLink} to="/retourbeleid">
            <Typography
              variant="body2"
              component="span"
              className={classes.kpi}
            >
              <KeyboardReturnRounded
                fontSize="inherit"
                className={classes.mr}
              />
              Gratis retour binnen 14 dagen
            </Typography>
          </Link>

          <Link
            component={GatsbyLink}
            to="/contact"
            className={classes.contactKpi}
          >
            <Typography
              variant="body2"
              component="span"
              className={classes.kpi}
            >
              <Mail fontSize="inherit" className={classes.mr} />
              Neem contact met ons op
            </Typography>
          </Link>
        </div>
      </Container>
    </div>
  )
}

export default TopBarView
