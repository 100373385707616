import React from "react"

import {
  Button,
  Container,
  Grid,
  Link,
  makeStyles,
  Typography,
} from "@material-ui/core"

import { Link as GatsbyLink } from "gatsby"

const useStyles = makeStyles(theme => ({
  root: {
    color: theme.palette.grey[700],
    backgroundColor: theme.palette.grey[200],
    padding: theme.spacing(4, 0),

    [theme.breakpoints.up(`sm`)]: {
      padding: theme.spacing(6, 0),
    },
  },

  button: {
    border: `1px ${theme.palette.grey[700]} solid`,
    "&:hover": {
      backgroundColor: theme.palette.grey[300],
    },
  },

  title: {
    marginBottom: theme.spacing(1),
  },
}))

const FooterView = () => {
  const classes = useStyles()
  return (
    <div className={classes.root}>
      <Container maxWidth="lg">
        <Grid container spacing={2} justify="space-between">
          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" className={classes.title}>
              Klantenservice
            </Typography>

            <Typography>
              <Link component={GatsbyLink} to="/retourbeleid">
                Retourbeleid
              </Link>
              <br />
              <Link component={GatsbyLink} to="/verzenden-en-betalen">
                Verzenden en betalen
              </Link>
              <br />
              <Link component={GatsbyLink} to="/zakelijk">
                Zakelijk
              </Link>
            </Typography>
          </Grid>

          <Grid item xs={12} sm={6} md={3}>
            <Typography variant="h3" className={classes.title}>
              Contact
            </Typography>

            <Typography paragraph={true}>
              Heb je een vraag of opmerking? Neem dan contact met ons op.
            </Typography>

            <Button
              variant="outlined"
              size="small"
              component={GatsbyLink}
              to="/contact"
              color="inherit"
              className={classes.button}
              fullWidth
            >
              Contactformulier
            </Button>
          </Grid>
        </Grid>
      </Container>
    </div>
  )
}

export default FooterView
