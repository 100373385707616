import { combineReducers } from "redux"

import alert from "./alert/reducers"
import cart from "./cart/reducers"
import order from "./order/reducers"

const rootReducer = combineReducers({
  alert,
  cart,
  order,
})

export default rootReducer
