import { connect } from "react-redux"

import { State } from "../../../redux/types"
import { ViewProps } from "./nav-bar-types"
import NavBarView from "./nav-bar-view"

const mapStateToProps = (state: State): ViewProps => {
  return {
    cartCount: state.cart.reduce(
      (count, cartRecord) => count + cartRecord.quantity,
      0
    ),
  }
}

export default connect(mapStateToProps)(NavBarView)
