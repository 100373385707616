import { OrderAction, OrderActionType, OrderState } from "./types"
import { Country, PaymentMethod } from "../../api/sync"

const initialState: OrderState = {
  ref: ``,
  details: {
    email: ``,
    paymentMethod: PaymentMethod.IDEAL,
    invoiceSameAsShipping: true,
    shippingFirstName: ``,
    shippingLastName: ``,
    shippingCompanyName: ``,
    shippingStreet: ``,
    shippingStreetNumber: ``,
    shippingPostalCode: ``,
    shippingCity: ``,
    shippingCountry: Country.NETHERLANDS,
    invoiceFirstName: ``,
    invoiceLastName: ``,
    invoiceCompanyName: ``,
    invoiceStreet: ``,
    invoiceStreetNumber: ``,
    invoicePostalCode: ``,
    invoiceCity: ``,
    invoiceCountry: Country.NETHERLANDS,
  },
}

export default function(state = initialState, action: OrderAction): OrderState {
  switch (action.type) {
    case OrderActionType.ORDER_RESET: {
      return initialState
    }

    case OrderActionType.ORDER_SET_DETAILS: {
      return {
        ...state,
        details: {
          ...state.details,
          ...action.payload.details,
        },
      }
    }

    case OrderActionType.ORDER_SET_REF: {
      return {
        ...state,
        ref: action.payload.ref,
      }
    }

    case OrderActionType.ORDER_SET_SHIPPING_COUNTRY: {
      return {
        ...state,
        details: {
          ...state.details,
          shippingCountry: action.payload.country,
        },
      }
    }

    default:
      return state
  }
}
